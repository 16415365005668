@import "./assets/css/reset.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto+Mono:400,700|Roboto:300,400,700&display=swap");
::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
