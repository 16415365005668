$Roboto: "Roboto", serif;
$RCondensed: "Roboto Condensed", sans-serif;
$RMono: "Roboto Mono", monospace;
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $RCondensed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  line-height: 1.4;
}

button {
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  outline: none;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1700px;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.btn {
  display: flex !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.borderRed {
  border: 1px solid #e91e63 !important;
  border-radius: 0 !important;
  background: #e91e63 !important;
  border-radius: 20px !important;
  span {
    font-weight: 700;
    color: #fff;
  }
  &:hover {
    border: 1px solid #af003b !important;
    background: #af003b !important;
  }
}

.text-center {
  text-align: center;
}

.dialogDefault {
  @media screen and (max-width: 450px) {
    padding: 20px;
  }
  .MuiDialog-paper {
    @media screen and (max-width: 450px) {
      width: 100%;
      max-height: 100%;
      margin: 0;
    }
  }
  .wrap {
    padding: 20px;
  }
}

// login and reg pages and some modals
.formLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 15px;
  background: linear-gradient(90deg, #000000ad 0%, #5d5959b0 50%, #000000b6 100%),
    url("./assets/img/formLayout_bg.jpg") no-repeat center/cover;
  .close {
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    svg {
      margin-right: 10px;
    }
  }
}

.form {
  position: relative;
  width: 100%;
  max-width: 440px;
  .title {
    font-size: 28px;
    font-weight: 700;
    position: absolute;
    top: -50px;
    left: 50%;
    width: 80%;
    // max-width: max-content;
    // min-width: 80%;
    padding: 30px;
    transform: translateX(-50%);
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    background: #c2185b;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .formik {
    width: 100%;
    padding: 80px 20px 20px 20px;
    border-radius: 5px;
    background: #fff;
    .formik-error {
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 4px;
      color: #f44336;
      font-size: 0.75rem;
      text-align: left;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }
    .checkEmail {
      text-align: center;
      .checkEmail-close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #000;
        font-size: 1.5rem;
        cursor: pointer;
      }
      .checkEmail-title {
        color: black;
        font-size: 21px;
        font-weight: 700;
      }
      .checkEmail-line {
        display: block;
        max-width: 465px;
        height: 1px;
        background-color: #ed1c24;
        margin: 25px auto 30px;
      }
      .checkEmail-text {
        font-size: 15px;
        color: #2a2a2a;
        max-width: 495px;
      }
      .checkEmail-text-block {
        margin-bottom: 15px;
        .checkEmail-text {
          font-size: 15px;
          color: #2a2a2a;
          margin-bottom: 8px;
        }
        .checkEmail-text-li {
          padding: 0 35px;
          font-size: 15px;
          text-align: left;
          margin-bottom: 8px;
        }
      }
      .checkEmail-email {
        color: #c2185b;
        font-size: 25px;
        font-weight: 700;
      }
    }

    &_visualizations {
      font-size: 16px;
      padding-top: 60px;
      .text {
        text-align: center;
        &.left {
          text-align: left;
          font-weight: 700;
        }
        .link {
          input {
            color: #006bde;
            font-weight: 400;
          }
        }
        .uploadWrap {
          label {
            border: 2px solid #ccc;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 248px;
            margin: 0 auto 30px;
            padding: 30px 0;
            cursor: pointer;
            span {
              font-weight: 700;
              text-align: center;
              color: #606060;
              margin-top: 5px;
            }
          }
          input[type="file"] {
            display: none;
          }
        }
        .names {
          .name {
            display: flex;
            align-items: flex-end;
            span {
              padding-bottom: 5px;
              padding-right: 5px;
              white-space: nowrap;
              min-width: 130px;
            }
          }
        }
      }
      .btns {
        max-width: max-content;
        margin-left: auto;
      }
    }
    &_reg {
      min-height: 555px;
      &.loading {
        display: flex;
        align-items: center;
        justify-content: center;
        .loader {
          width: 150px !important;
          height: 150px !important;
        }
      }
    }
    .inputWithIcon {
      display: flex;
      align-items: flex-end;
      > svg {
        margin: 0 7px 5px 0;
      }
    }
    .forgot {
      font-size: 14px;
      margin: 10px 0 5px;
      cursor: pointer;
      text-align: center;
      text-decoration: underline;
      color: #006ce5;
    }
    .btn {
      font-size: 18px;
      font-weight: 700;
      max-width: 150px;
      text-transform: uppercase;
      color: #ec407a;
    }
    .instruction {
      font-size: 18px;
      text-align: center;
    }
    .terms {
      .MuiFormControl-root,
      .MuiTypography-body1 {
        width: 100%;
      }
      .span {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .btn {
        font-size: 14px;
        max-width: 100%;
        font-weight: 400;
        text-decoration: underline;
        text-transform: none;
        color: #006ce5;
        width: 75px !important;
        margin: 0 !important;
        &:hover {
          background: none;
        }
      }
      .terms-btn {
        width: 200px !important;
      }
    }
  }
  &_thnx {
    .title {
      font-size: 30px;
      line-height: 1.5;
      padding: 20px 40px;
      text-align: center;
      text-transform: none;
      @media screen and (max-width: 768px) {
        font-size: 27px;
        line-height: 1.2;
        padding: 10px;
      }
    }
    .formik {
      padding: 100px 40px 20px 40px;
      @media screen and (max-width: 768px) {
        padding: 50px 20px 20px;
      }
      @media screen and (max-width: 345px) {
        padding-top: 80px;
      }
    }
  }
}

.dialogDefault {
  .MuiPaper-root {
    background: transparent !important;
    box-shadow: none !important;
  }
  .form {
    position: relative;
    max-width: 100%;
    overflow-y: hidden;
    .title {
      top: 0;
      text-transform: none;
      z-index: 3;
    }
    .formik {
      margin-top: 50px;
      max-height: calc(100vh - 130px);
      overflow-y: scroll;
      height: 100%;
    }
  }
}
